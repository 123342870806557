import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
class PaymentConfirmed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Ticket',
            
        }
     }
     componentDidMount(){
        window.$('.modal-backdrop').remove();
     }
     
    
    scrollleft_table(vvalue,hvalue1){
    
        $("#scrollcartitem").scrollLeft(vvalue);
        $("#scrollpayment").scrollTop(hvalue1);
        
      }
      greatingsmessage(){
        var greeting;
        var time = new Date().getHours();
        
        if (time > 5 && time < 12) {
          greeting = this.props.language_conversions[this.props.languageindex]["goodmorning"];
        } else if (time < 16) {
          greeting = this.props.language_conversions[this.props.languageindex]["goodafternoon"];
        } else if (time < 20){
          greeting = this.props.language_conversions[this.props.languageindex]["goodevening"];
        }else{
          greeting = this.props.language_conversions[this.props.languageindex]["goodday"];
        }
        return greeting;
      }
      change_dateandtime(langind,langconver,str){
        if(langind == 0){
            return str;
        }else{
            let day=moment(str).format("ddd")
            let year=moment(str).format("YYYY")
            let month=moment(str).format("MM")
            let dates=moment(str).format("DD")
            let time=moment(str).format("hh:mm A")
            let dayval;
             if(day === "Mon")
             dayval=langconver[langind]["mon"]
             if(day === "Tue")
             dayval=langconver[langind]["tue"]
             if(day === "Wed")
             dayval=langconver[langind]["wed"]
             if(day === "Thu")
             dayval=langconver[langind]["thu"]
             if(day === "Fri")
             dayval=langconver[langind]["fri"]
             if(day === "Sat")
             dayval=langconver[langind]["sat"]
             if(day === "Sun")
             dayval=langconver[langind]["sun"]
            return dayval+", "+year+" 年 "+month+" 月 "+dates+" 日 "+time;
 
        }
    }
    change_bookingfee(langind,langconver,str){
        var res = str.replace("Booking Fee", langconver[langind]["bookingfee"]);
        return res
       }
       change_event(langind,langconver,str){
        let retun_result;
        if(str == "EVENT")
        retun_result=langconver[langind]["event"];
        else
        retun_result=str;

        return retun_result;
   }

   changeLabel(str){
        let newName = str;

        if(newName === "Select a Payment method"){
            newName = "Payment Method"
        }
        return newName;
    }

    render() {  
      const {language_conversions,getPrePaymentOrder,payment_completed,languageindex} = this.props;
      let {scrollconfirmedHorizontal,scrollconfirmedVertical}=this.props;
      let checkPaymentMethods = this.props.Selected_PaymentMethods;
      this.scrollleft_table(scrollconfirmedHorizontal,scrollconfirmedVertical);
      let sum = 0,subtotal=0,unitPrice=0;
      for (let num of getPrePaymentOrder.ticket_result){
        sum = sum + num.qty;
      }
      for (let num of getPrePaymentOrder.ticket_result){
          subtotal = subtotal + parseFloat(num.subTotal);
        }
      for (let num of getPrePaymentOrder.ticket_result){
          unitPrice = unitPrice + parseFloat(num.unitPrice);
        }
        let indval=0;
        // [START] ST-12241
        let logoImagePath;
        if(!localStorage.getItem("logoImage")){
            logoImagePath = "/images/sistic-logo.png";
        } else {
            logoImagePath = localStorage.getItem("logoImage");
            logoImagePath = this.props.imagekeyvalidations(logoImagePath);
        }
        // [END] ST-12241

        return (<React.Fragment>
            <div className="container-fluid">
                <div className="row">
                <header className="container-fluid">
                <div className="nav-brand float-left" style={{width:'7vw'}}><img src={logoImagePath} className="img-fluid" style={{objectFit: 'contain', width:'100%', height: '5vh'}} alt="SISTIC" /></div>
                <div className="float-right user-encl">
                   
                </div>
                </header>
                <section className="main-content container-fluid">
                    <div className="bglight-block mt-4 vh-min cart-item" id="scrollpayment">
                    <div className="confirmed-head clearfix">
                    <h4 className="title float-left"><img src="/images/round-done-button.svg" alt="icon"/>{language_conversions[languageindex]["bookingconfirmed"]}</h4>
                    <div className="float-right alert-pop text-center">
                        <h5 className="text-success">{language_conversions[languageindex]["transactioncompleted"]}</h5>
                        <p className="mb-0">{language_conversions[languageindex]["transactionid"]} - {payment_completed.transaction_id} </p>
                    </div>
                    </div>
                    <div className="table-responsive"  id="scrollcartitem">
                        <table className="table table-bordered mb-0">
                        <thead>
                            <tr>
                            <th>{language_conversions[languageindex]["no"]} </th>
                            <th>{language_conversions[languageindex]["item"]}</th>
                            <th>{language_conversions[languageindex]["description"]}</th>
                            <th>{language_conversions[languageindex]["level"]}</th>
                            <th>{language_conversions[languageindex]["section"]}</th>
                            <th>{language_conversions[languageindex]["row"]}</th>
                            <th>{language_conversions[languageindex]["seatno"]}</th>
                            <th>{language_conversions[languageindex]["priceclass"]}</th>
                            <th>{language_conversions[languageindex]["unitprice"]}</th>
                            <th>{language_conversions[languageindex]["quantity"]}</th>
                            <th>{language_conversions[languageindex]["subtotal"]}</th>
                            </tr>
                        </thead>
                        <tbody>
                        { 
                        getPrePaymentOrder.ticket_result.map((ticketlist,ind)=>{
                            if(ticketlist.item)
                            {
                                {indval++}
                                return(
                                <tr key={ind}>
                                <td>{ indval}</td>
                                <td>{this.change_event(languageindex,language_conversions,ticketlist.item)}</td>
                                <td style={{width:'31%'}}>       
                                    <p>
                                        { this.props.languageconversion_onpayment(ticketlist.productDescription,languageindex) } <br /> 
                                        { this.change_dateandtime(languageindex,language_conversions,ticketlist.dateAndTime) }<br />
                                        {this.props.languageconversion_onpayment(ticketlist.venueName,languageindex)}
                                    </p>
                                </td>
                                <td> <p dangerouslySetInnerHTML={{__html:this.props.languageconversion_onpayment(ticketlist.level,languageindex)}} /></td> 
                                <td><p dangerouslySetInnerHTML={{__html:this.props.languageconversion_onpayment(ticketlist.section,languageindex)}} /> </td>
                                <td> <p dangerouslySetInnerHTML={{__html: ticketlist.row !== "" ? ticketlist.row : " " }} /></td>
                                 <td>
                                    <p dangerouslySetInnerHTML={{__html:ticketlist.seatsNo}} />
                                </td>
                                <td>{this.props.languageconversion_onpayment(ticketlist.priceClass,languageindex)}</td>
                                <td>$ {parseFloat(ticketlist.unitPrice).toFixed(2)}</td>
                                <td>{ticketlist.qty}</td>
                                <td>$ {parseFloat(ticketlist.subTotal).toFixed(2)}</td>
                                </tr>);
                            }
                        })}
                        { 
                        getPrePaymentOrder.ticket_result.map((ticketlist,ind)=>{
                            if(!ticketlist.item)
                            {
                                {indval++}
                                return(
                                <tr key={ind}>
                                <td>{ indval}</td>
                                <td>{ticketlist.item}</td>
                                <td style={{width:'31%'}}>       
                                    <p>
                                         {/* {this.props.languageconversion_onpayment(this.change_bookingfee(languageindex,language_conversions,ticketlist.Description),languageindex)} 
                                         {ticketlist.bookingFeeType && "-" } */}
                                        {this.props.languageconversion_onpayment(this.change_bookingfee(languageindex,language_conversions,ticketlist.Description),languageindex)}
                                    </p>
                                </td>
                                <td> <p dangerouslySetInnerHTML={{__html:this.props.languageconversion_onpayment(ticketlist.level,languageindex)}} /></td> 
                                <td></td>
                                <td> <p dangerouslySetInnerHTML={{__html:ticketlist.row !== "" ? ticketlist.row : " " }} /></td>
                                 <td>
                                    <p dangerouslySetInnerHTML={{__html:ticketlist.seatsNo}} />
                                </td>
                                <td></td>
                                <td>$ {isNaN(parseFloat(ticketlist.unitPrice).toFixed(2))? "0.00" : parseFloat(ticketlist.unitPrice).toFixed(2)}</td>
                                <td>{ticketlist.qty}</td>
                                <td>$ {parseFloat(ticketlist.subTotal).toFixed(2)}</td>
                                </tr>);
                            }
                        })} 
                        </tbody>
                        </table>
                    </div>
                    <div className="row py-3">
                        <div className="col-8">
                            <div className="alert-pop mb-0" style={{display: 'none'}}>
                                {/* <div className="row">
                                    <div className="col-lg-3 col-md-6 mar-b-md">
                                    <span className="title">{language_conversions[languageindex]["payment"]}</span>
                                    <span className="desc">{payment_completed.payment_method}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mar-b-md">
                                    <span className="title">{language_conversions[languageindex]["payableamount"]}</span>
                                    <span className="desc">{payment_completed.payable_amount} </span>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <span className="title">{language_conversions[languageindex]["received"]}</span>
                                    <span className="desc">{payment_completed.received}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <span className="title">{language_conversions[languageindex]["change"]}</span>
                                    <span className="desc">{payment_completed.value_change ? payment_completed.value_change: '-'}</span>
                                    </div>
                                </div> */}

                                {/*(checkPaymentMethods && checkPaymentMethods.amount1 && parseFloat(checkPaymentMethods.amount1) > 0 ) && <div className="row">
                                    <div className="col-lg-3 col-md-6 mar-b-md">
                                    <span className="title">{language_conversions[languageindex]["payment"]}</span>
                                    <span className="desc">{this.changeLabel(checkPaymentMethods.paymentMethodName1)}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mar-b-md">
                                    <span className="title">{language_conversions[languageindex]["payableamount"]}</span>
                                    <span className="desc">{'$' + checkPaymentMethods.amount1}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <span className="title">{language_conversions[languageindex]["received"]}</span>
                                    <span className="desc"></span>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <span className="title">{language_conversions[languageindex]["change"]}</span>
                                    <span className="desc">{'-'}</span>
                                    </div>
                            </div>*/}

                                {(checkPaymentMethods && checkPaymentMethods.amount2 && parseFloat(checkPaymentMethods.amount2) > 0 ) && <div className="row">
                                    <div className="col-lg-3 col-md-6 mar-b-md">
                                    <span className="desc">{this.changeLabel(checkPaymentMethods.paymentMethodName2)}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mar-b-md">
                                    <span className="desc">{'$' + checkPaymentMethods.amount2}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <span className="desc"></span>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <span className="desc">{'-'}</span>
                                    </div>
                                </div>}

                            </div>
                        </div>
                        <div className="col-4 text-right mt-3">
                            <span className="cart-price"><small>{language_conversions[languageindex]["granttotal"]} </small>$ {parseFloat(subtotal).toFixed(2)}</span>
                        </div>
                        </div>
                    </div>
                </section>
                
                </div> 
             
            </div>
           
            <div className="loading-wrap d-none">
                <div className="loading-container ">
                <img className="icon" src="/images/print-icon.svg" alt="icon" />
                <p>{language_conversions[languageindex]["printing"]}</p>
                <h4>{language_conversions[languageindex]["yourticketgettingready"]}</h4>
                </div>
            </div>
        </React.Fragment>);
    }
}
export default PaymentConfirmed;