import React, { Component, Fragment } from 'react';
import moment from 'moment';
import $ from 'jquery';
class PaymentOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Ticket',
            terms:[],
        }
     }
     componentDidMount(){
        $(".loading-wrap").addClass("d-none");
        //setTimeout(function() { $(".loading-wrap").addClass("d-none"); }, 1000);
     }
     trimforpaymentID(str){
        return str.replace(" ","");
     }
     
    update_agree(){
        $("#imgClickAndChange").attr("src","/images/checked-circle.png");
        this.setState({controlagreeopen:null,controlagreeclose:null});
        window.$('#myModal').modal('hide');
    }
 getUnique(arr, index) {
        const unique = arr
             .map(e => e[index])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);      
         return unique;
      }
      viewImage(str){
        var obj={
            "CASH":"/images/cash-icon.png",
            "Octopus":"/images/octopus-icon.png",
            "tapgo":"/images/tapgo-icon.png",
            "VISA":"/images/visa-icon.png",
            "MasterCard":"/images/mastercc.png",
            "AMEX":"/images/amex-icon.png",
            "JCB":"/images/jcb-icon.png",
            "UnionPay":"/images/unionpay-icon.png",
            "WeChat Pay":"/images/wechat-icon.png",
            "Wechat Pay":"/images/wechat-icon.png",
            "China UnionPay":"/images/unionpay.png",
            "CUP":"/images/unionpay.png",
            "NETS":"/images/nets-pay.png",
            "Diners":"/images/diners-club.png",
            "American Express":"/images/amex-icon.png"
            
        };
        
           
           if(obj[str] !== undefined)
           return obj[str];
           else
           return "/images/common-pay-icon.png";
      }
      
      scrollleft_table(vvalue,hvalue1){
        if(vvalue)
        $("#scrollcartitem").scrollLeft(vvalue);
        if(hvalue1)
        $("#scrollpayment").scrollTop(hvalue1);
        
      }
      scrollagree(vvalue,hvalue1){
        if(vvalue)
        $("#scrollagree").scrollLeft(vvalue);
        if(hvalue1)
        $("#scrollagree").scrollTop(hvalue1);
          
      }
      greatingsmessage(){
        var greeting;
        var time = new Date().getHours();
        console.log("hours",time)
        if (time > 5 && time < 12) {
            greeting = this.props.language_conversions[this.props.languageindex]["goodmorning"];
        } else if (time < 16) {
          greeting = this.props.language_conversions[this.props.languageindex]["goodafternoon"];
        } else if (time < 20){
          greeting = this.props.language_conversions[this.props.languageindex]["goodevening"];
        }else{
          greeting = this.props.language_conversions[this.props.languageindex]["goodday"];
        }
        return greeting;
      }
      change_dateandtime(langind,langconver,str){
        if(langind == 0){
            return str;
        }else{
            let day=moment(str).format("ddd")
            let year=moment(str).format("YYYY")
            let month=moment(str).format("MM")
            let dates=moment(str).format("DD")
            let time=moment(str).format("hh:mm A")
            let dayval;
             if(day === "Mon")
             dayval=langconver[langind]["mon"]
             if(day === "Tue")
             dayval=langconver[langind]["tue"]
             if(day === "Wed")
             dayval=langconver[langind]["wed"]
             if(day === "Thu")
             dayval=langconver[langind]["thu"]
             if(day === "Fri")
             dayval=langconver[langind]["fri"]
             if(day === "Sat")
             dayval=langconver[langind]["sat"]
             if(day === "Sun")
             dayval=langconver[langind]["sun"]
            return dayval+", "+year+" 年 "+month+" 月 "+dates+" 日 "+time;
 
        }
    }
    change_bookingfee(langind,langconver,str){
        var res = str.replace("Booking Fee", langconver[langind]["bookingfee"]);
        return res
       }
       change_event(langind,langconver,str){
        let retun_result;
        if(str == "EVENT")
        retun_result=langconver[langind]["event"];
        else
        retun_result=str;

        return retun_result;
    }

    changeLabel(str){
        let newName = str;

        if(newName === "Select a Payment method"){
            newName = "Payment Method"
        }
        return newName;
    }


    render() {  
        
      const {language_conversions,getPrePaymentOrder,languageindex,agree_checkbox} = this.props;
      let {scrollagreeHorizontal,scrollPaymentVertical,scrollpaymentHorizontal,scrollagreeVertical}=this.props;
      this.scrollleft_table(scrollPaymentVertical,scrollpaymentHorizontal);
      this.scrollagree(scrollagreeVertical,scrollagreeHorizontal);
      let payment_details=this.props;
      let Select_PaymentMethod=this.props;
      if(Select_PaymentMethod.Select_PaymentMethod)
      {
        var ids="#"+Select_PaymentMethod.Select_PaymentMethod;
        $(ids).css("border","2px solid red"); 
    }
      let filterdpayment=this.getUnique(payment_details.payment_details,"paymentmethod");
      let checkPaymentMethods = this.props.Selected_PaymentMethods;
     
    // { filterdpayment && filterdpayment.map((res,ind)=>{
    //     var npmt = res.paymentmethod.toLowerCase().replace(/[^a-zA-Z ]/g, "");
    //     //var ids=["#fpm",npmt.replace(" ","")].join('');
    //     var ids=["#fpm",ind].join('');
    //     $(ids).css("border",""); 
    // })
    // }
    if(Select_PaymentMethod.Select_PaymentMethod)
    {
      var ids="#"+(Select_PaymentMethod.Select_PaymentMethod).replace(" ","");
      $(ids).css("border","2px solid red"); 
    }
      let sum = 0,subtotal=0,unitPrice=0;
      for (let num of getPrePaymentOrder.ticket_result){
        sum = sum + num.qty;
      }
      for (let num of getPrePaymentOrder.ticket_result){
          subtotal = parseFloat(subtotal) + parseFloat(num.subTotal);
        }
      for (let num of getPrePaymentOrder.ticket_result){
          unitPrice = unitPrice + parseInt(num.unitPrice);
        }
        let indval=0,totalcart=0;
        getPrePaymentOrder.ticket_result.map((ticketlist,ind)=>{
        if(ticketlist.item)
        {
            totalcart++;
        }})
       
        if(agree_checkbox)
        {
        $("#imgClickAndChange").attr("src","/images/checked-circle.png");
        
        }
        else
        {
        $("#imgClickAndChange").attr("src","/images/check-circle.png");
        }
      let {lineItemist, totalLineItems: totalCartItems, lineItemTotal} = getPrePaymentOrder;
      // [START] ST-12241
      let logoImagePath;
      if(!localStorage.getItem("logoImage")){
          logoImagePath = "/images/sistic-logo.png";
      } else {
          logoImagePath = localStorage.getItem("logoImage");
          logoImagePath = this.props.imagekeyvalidations(logoImagePath);
      }
      // [END] ST-12241

        return (<React.Fragment>
            <div className="container-fluid">
                <div className="row">
                <header className="container-fluid">
                <div className="nav-brand float-left" style={{width:'7vw'}}><img src={logoImagePath} className="img-fluid" style={{objectFit: 'contain', width:'100%', height: '5vh'}} alt="SISTIC" /></div>
                <div className="float-right user-encl">
                  
                </div>
                </header>
                {/* Content Section Starts here */}
                <section className="main-content container-fluid">
                    <div className="bglight-block mt-4 vh-min cart-item" id="scrollpayment">
                    <h4 className="title border-bottom">{language_conversions[languageindex]["yourcartitems"]} ({totalcart})</h4>
                    <div className="table-responsive" id="scrollcartitem">
                        <table className="table table-bordered mb-0">
                        <thead>
                            <tr>
                            <th>{language_conversions[languageindex]["no"]} </th>
                            <th>{language_conversions[languageindex]["item"]}</th>
                            <th>{language_conversions[languageindex]["description"]}</th>
                            <th>{language_conversions[languageindex]["level"]}</th>
                            <th>{language_conversions[languageindex]["section"]}</th>
                            <th>{language_conversions[languageindex]["row"]}</th>
                            <th>{language_conversions[languageindex]["seatno"]}</th>
                            <th>{language_conversions[languageindex]["priceclass"]}</th>
                            <th>{language_conversions[languageindex]["unitprice"]}</th>
                            <th>{language_conversions[languageindex]["quantity"]}</th>
                            <th>{language_conversions[languageindex]["subtotal"]}</th>
                            </tr>
                        </thead>
                        <tbody>
                        { 
                        getPrePaymentOrder.ticket_result.map((ticketlist,ind)=>{
                            if(ticketlist.item)
                            {
                                {indval++}
                                return(
                                <tr key={ind}>
                                <td>{ indval}</td>
                                <td>{this.change_event(languageindex,language_conversions,ticketlist.item)}</td>
                                <td style={{width:'31%'}}>       
                                    <p>
                                        { this.props.languageconversion_onpayment(ticketlist.productDescription,languageindex) } <br /> 
                                        { this.change_dateandtime(languageindex,language_conversions,ticketlist.dateAndTime) }<br />
                                        {this.props.languageconversion_onpayment(ticketlist.venueName,languageindex)}
                                    </p>
                                </td>
                                <td> <p dangerouslySetInnerHTML={{__html:this.props.languageconversion_onpayment(ticketlist.level,languageindex)}} /></td> 
                                <td>  <p dangerouslySetInnerHTML={{__html: this.props.languageconversion_onpayment(ticketlist.section,languageindex)}} /> </td>
                                <td><p dangerouslySetInnerHTML={{__html: ticketlist.row !== "" ? ticketlist.row : " " }} /></td>
                                 <td>
                                    <p dangerouslySetInnerHTML={{__html: ticketlist.seatsNo}} />
                                </td>
                                <td>{this.props.languageconversion_onpayment(ticketlist.priceClass,languageindex)}</td>
                                <td>$ {parseFloat(ticketlist.unitPrice).toFixed(2)}</td>
                                <td>{ticketlist.qty}</td>
                                <td>$ {parseFloat(ticketlist.subTotal).toFixed(2)}</td>
                                </tr>);
                            }
                        })}
                        { 
                        getPrePaymentOrder.ticket_result.map((ticketlist,ind)=>{
                            if(!ticketlist.item)
                            {
                                {indval++}
                                return(
                                <tr key={ind}>
                                <td>{ indval}</td>
                                <td>{ticketlist.item}</td>
                                <td style={{width:'31%'}}>       
                                    <p>
                                         {/* {this.props.languageconversion_onpayment(this.change_bookingfee(languageindex,language_conversions,ticketlist.Description),languageindex)} 
                                         {ticketlist.bookingFeeType && "-" } */}
                                        {this.props.languageconversion_onpayment(this.change_bookingfee(languageindex,language_conversions,ticketlist.Description),languageindex)}
                                    </p>
                                </td>
                                <td> <p dangerouslySetInnerHTML={{__html:this.props.languageconversion_onpayment(ticketlist.level,languageindex)}} /></td> 
                                <td></td>
                                <td> <p dangerouslySetInnerHTML={{__html:ticketlist.row !== "" ? ticketlist.row : " " }}/></td>
                                 <td>
                                    <p dangerouslySetInnerHTML={{__html: ticketlist.seatsNo}} />
                                </td>
                                <td></td>
                                <td>{isNaN(parseFloat(ticketlist.unitPrice).toFixed(2))? "" : '$ ' +parseFloat(ticketlist.unitPrice).toFixed(2)}</td>
                                <td>{ticketlist.qty}</td>
                                <td>$ {parseFloat(ticketlist.subTotal).toFixed(2)}</td>
                                </tr>);
                            }
                        })} 
                        </tbody>
                        </table>
                    </div>
                    <div className="row py-3">
                        <div className="col-6">
                        {/* <div className="checkbox" data-toggle="modal" data-target="#myModal">
                            <img id="imgClickAndChange" src="/images/check-circle.png" />
                            <img className="d-none" src="/images/checked-circle.png" />
                            <span>{language_conversions[languageindex]["agreetoterms"]}</span>
                        </div> */}
                        </div>
                        <div className="col-6 text-right">
                        <span className="cart-price"><small>{language_conversions[languageindex]["granttotal"]} </small>$ {parseFloat(subtotal).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row">
                        {/*  <h5 className="col-12 fw-600 mb-4">{language_conversions[languageindex]["paymentoption"]} </h5>
                       <div className="col-lg-12 col-md-12 px-0 grid-3" style={{margin: "0px 15px"}}>
                            <h6 className="col"></h6>
                            { /*filterdpayment.map((res,ind)=>{
                                if(ind < 6)
                                return(<React.Fragment key={ind}> <div  className="pay-item" id={(res.paymentmethod).replace(" ","")}><img src={ this.viewImage(res.paymentmethod) } alt="icon" />{this.trimforpaymentID(res.paymentmethod)}</div></React.Fragment>);
                            })
                            
                                                
                        </div> */}
                        {/* <div className="col-lg-12 col-md-12 px-0 grid-3" style={{margin: "0px 15px"}}>
                            <h6 className="col"></h6>
                            { /* filterdpayment.map((res,ind)=>{
                                if(ind >= 6)
                                return(<React.Fragment key={ind}> <div className="pay-item"  id={this.trimforpaymentID(res.paymentmethod)}><img src={ this.viewImage(res.paymentmethod) } 
                                alt="icon" />{this.trimforpaymentID(res.paymentmethod)}</div></React.Fragment>);
                            })
                            *
                        </div> 
                        <div className="col-lg-8 col-md-12 grid-3 alert-pop" style={{marginLeft: '15px'}}>
                            {(checkPaymentMethods && checkPaymentMethods.amount1 && parseFloat(checkPaymentMethods.amount1) > 0 ) && <div className="row">
                                <div className="col-lg-6 col-md-6 mar-b-md">
                                <span className="title">{language_conversions[languageindex]["payment"]}</span>
                                <span className="desc">{this.changeLabel(checkPaymentMethods.paymentMethodName1)}</span>
                                </div>
                                <div className="col-lg-6 col-md-6 mar-b-md">
                                <span className="title">{language_conversions[languageindex]["payableamount"]}</span>
                                <span className="desc">{'$' + checkPaymentMethods.amount1}</span>
                                </div>
                            </div>}

                            {(checkPaymentMethods && checkPaymentMethods.amount2 && parseFloat(checkPaymentMethods.amount2) > 0 ) && <div className="row">
                                <div className="col-lg-6 col-md-6 mar-b-md">
                                <span className="desc">{this.changeLabel(checkPaymentMethods.paymentMethodName2)}</span>
                                </div>
                                <div className="col-lg-6 col-md-6 mar-b-md">
                                <span className="desc">{'$' + checkPaymentMethods.amount2}</span>
                                </div>
                            </div>}
                        </div>
                        */}

                    </div>
                    </div>
                </section>
               
                </div> 
                <div className="modal fade tc-content" id="myModal" tabIndex={-1} role="dialog" aria-labelledby aria-hidden="true">
                <div className="modal-dialog modal-fwidth modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <div className="modal-body">
                        <div className="container-fluid">
                        <div className="row tc-main" id="scrollagree">
                            <div className="col-12">
                            { 
                            
                                languageindex == 2 ?(
                                <Fragment>
                                <h2>票务条款及细则</h2>
                                <p>1. 一人一票，幼童不论年龄亦需凭票入场。</p>
                                <p>2. 门票一经售出，恕不退票或退款。</p>
                                <p>3. 如门票上的资料被删去或经过涂改，又或门票遭损毁或变得残缺不全，则持票人可能不获准入场。</p>
                                <p>4. 优惠票持有人必须出示有效的身份证明，方可入场。</p>
                                <p>5. 活动举办场地管理人有权不让迟到者入场，亦有权决定迟到者入场时间及方式。</p>
                                <p>6. 活动举办场地管理人有权要求持票人出示年龄证明文件，例如身份证。</p>
                                <p>7. 儿童购票安排将根据不同节目而有所不同。</p>
                                <p>8. 所有门票均按销售条款及场地规则发售。</p>
                                </Fragment>)
                                : languageindex == 1 ?(
                                <Fragment>
                                    <h2>票務條款及細則</h2>

                                    <p>1. 一人一票，幼童不論年齡亦需憑票入場。</p>
                                    <p>2. 門票一經售出，恕不退票或退款。</p>
                                    <p>3. 如門票上的資料被刪去或經過塗改，又或門票遭損毀或變得殘缺不全，則持票人可能不獲准入場。</p>
                                    <p>4. 優惠票持有人必須出示有效的身份證明，方可入場。</p>
                                    <p>5. 活動舉辦場地管理人有權不讓遲到者入場，亦有權決定遲到者入場時間及方式。</p>
                                    <p>6. 活動舉辦場地管理人有權要求持票人出示年齡證明文件，例如身份證。</p>
                                    <p>7. 兒童購票安排將根據不同節目而有所不同。</p>
                                    <p>8. 所有門票均按銷售條款及場地規則發售。</p>
                                     
                                </Fragment>) :
                                (<Fragment>
                                    <h2>Terms &amp; Conditions</h2>
                                    <p>1. One person per ticket, regardless of age.</p>
                                    <p>2. Tickets cannot be exchanged nor payment refunded.</p>
                                    <p>3. Entry may be refused if any details on the ticket have been omitted or altered.</p>
                                    <p>4. Holders of concessionary tickets will be admitted only on production of proof of eligibility.</p>
                                    <p>5. Late-comers will be admitted only if there is a suitable break in the performance.</p>
                                    <p>6. Ticket-holders must produce proof of age if required to do so by the venue manager.</p>
                                    <p>7. Children’s admission arrangements vary according to the event</p>
                                    <p>8. All tickets are sold subject to the Terms and Conditions for Sale of Entertainment Tickets.</p>
                                </Fragment>)
                            }
                            
                            </div>
                        </div>
                        <div className="col-12 px-0 text-right">
                            <button className="btn btn-primary agree-btn" onClick={(e)=>this.update_agree()}>
                            <span><img className="img-fluid" src="/images/checked-circle.png" alt="icon" /></span>
                            {language_conversions[languageindex]["iagree"]}
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="loading-wrap">
                <div className="loading-container_paymentfail">
                <img className="icon" src="/images/payfail.png" alt="icon" />
                <h4>{language_conversions[languageindex]["paymentunsuccessful"]}</h4>
                </div>
            </div>
        </React.Fragment>);
    }
}
export default PaymentOption;

