import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

export default function Pairing(props) {
const location = useLocation();
const pathname = location.pathname.slice(1).split('/')

  const [state, setState] = useState({
        pairingId: props.pairingId,
        randomString: props.randomString,
        sccessmsg: props.sccessmsg,
        wl: pathname[0]
  });  


  useEffect(()=>{
    if(props){
        setState({
            ...state,
            ...props
        })
    }
  },[props.pairingId, props.randomString, props.sccessmsg])


  return (
    <div className="loading-wrap paired">
        <div className="loading-container-v2 ">
            <img src={["/images/pairing-",state.wl ? state.wl : 'sistic',"-logo.png"].join('')} className={['loadicon', state.wl ? state.wl : 'sistic'].join(' ')} />
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={['psload', state.wl ? state.wl : 'sistic'].join(' ')}>
                <path d="M38.3334 6.66666V16.6667H28.3334" stroke="transparent" stroke-width="3.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.66663 33.3333V23.3333H11.6666" stroke="transparent" stroke-width="3.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.84996 15C6.69524 12.6113 8.13185 10.4757 10.0257 8.79236C11.9196 7.10905 14.2091 5.93294 16.6804 5.37376C19.1518 4.81458 21.7245 4.89056 24.1586 5.59462C26.5927 6.29867 28.8087 7.60785 30.6 9.39999L38.3333 16.6667M1.66663 23.3333L9.39996 30.6C11.1912 32.3921 13.4072 33.7013 15.8413 34.4054C18.2754 35.1094 20.8481 35.1854 23.3195 34.6262C25.7908 34.0671 28.0803 32.8909 29.9742 31.2076C31.8681 29.5243 33.3047 27.3887 34.15 25" stroke="transparent" stroke-width="3.2" stroke-linecap="round" stroke-linejoin="round"/>
                <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0" to="360" dur="3s" additive="sum" repeatCount="indefinite" />
            </svg>
        <p><h2 className={['ptitle', state.wl ? state.wl : 'sistic'].join(' ')}>{state.pairingId}</h2></p>
        <h4 className={['pstitle', state.wl ? state.wl : 'sistic'].join(' ')}>{state.randomString}</h4>
        <h4>{state.sccessmsg}</h4>
        <h4></h4>
        </div>
    </div>
  )
}
