import React, { Component } from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import WhiteLabel from "./Whitelabel";
class App extends Component {
  constructor() {
    super();

  }
  
  componentWillMount() {

  }
  componentDidMount(){

  }

render() {

  return (
      <BrowserRouter>
          <Routes>
              <Route element={<WhiteLabel/>} path="/mbs/:id" />
              <Route element={<WhiteLabel/>} path="/sistic/:id" />
          </Routes>
        </BrowserRouter>);
  }
}
export default App;

// // welcomescreen?<Welcome />: