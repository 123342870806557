import React, { Component, Fragment } from 'react';
import moment from 'moment';
import $ from 'jquery';
class SelectDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
    title: 'Booking',
    updatedevenDate:"",
    eventAvailabilityDate:moment().format('YYYY-MM-DD'), 
    eventAvailability: [],
    availabilityStatus: [
      {status: 6, code: 'SHOW RECONFIG'},
      {status: 7, code: 'SHOW CANCELLED'},
      {status: 8, code: 'SHOW POSTPONED'},
      {status: 16, code: 'UNAVAILABLE/SOLD OUT', msg: 'Seats not Available'},
      {status: 17, code: 'SINGLE SEATS'},
      {status: 18, code: 'LIMITED SEATS'},
      {status: 19, code: 'AVAILABLE', msg: 'Seats Available'},
      {status: 20, code: 'COMING SOON'},
      ]
    }
}
componentDidMount(){
  var self=this;
  
  window.$('.modal-backdrop').remove();
  setTimeout(function() { $(".loading-wrap").addClass("d-none"); }, 1000);
  let {eventShowTimes,date_and_time_control} = this.props;
    eventShowTimes.map((o) => {
    let titles=(o.title).split("-");
    o['showDate_m'] = moment(o.start_date).format('YYYY-MM-DD');
    o['showTime_m'] = moment(o.start_date).format('HH:mm:ss');
    o['showcolor']=titles[1]
    })
   
    const groupedEvent = this.groupBy(eventShowTimes, 'showDate_m');
    let eventAvailability = groupedEvent[this.state.eventAvailabilityDate];
    this.setState({eventAvailability})
    let keys = Object.keys(groupedEvent)
    let event = keys.map((o) => ({title: groupedEvent[o].length, start: o}));
    let events = keys.map((o) => ({title: `${groupedEvent[o].length} `+this.props.language_conversions[this.props.languageindex]["show"], start: o,className:'custom'}));
    var calHeight = $(".calendar-sec").height();
   
    window.$('#calendar').fullCalendar({
      height: calHeight,
      selectable: true,
      header: {
      left: 'title',
      center: '',
      right: ''
      },
      defaultDate: new Date(date_and_time_control.eventStartDate),
      defaultView: 'month',
      editable: true,
      firstDay: 0, 
      events: events,
      eventRender: function (event, element, view) { 
        
        self.setState({x:view.el[0]});
        var dateString = event.start.format("YYYY-MM-DD");
        var myArray = ['#24b11342','#ffe8b2']; 
        var rand = myArray[Math.floor(Math.random() * myArray.length)];
        let zzz=1;
        eventShowTimes && eventShowTimes.map((xx,yy)=>{
          if(xx.showDate_m === dateString && xx.showcolor === 'green')
          zzz=0
  
        })
        
        window.$(view.el[0]).find('.fc-day[data-date=' + dateString + ']').css('background-color', myArray[zzz]);
        if(dateString == '2019-11-11'){
          window.$(view.el[0]).find('.fc-day[data-date=' + dateString + ']').css('border','2px solid green'); 
        }
      }
    });
    $('.fc-left').html(self.change_dateshortformat(eventShowTimes[0].selectedMonthYear[0],eventShowTimes[0].selectedMonthYear[1]));
    $(".fc-day-header.fc-widget-header.fc-sun").text(this.props.language_conversions[this.props.languageindex]["sun"])
    $(".fc-day-header.fc-widget-header.fc-mon").text(this.props.language_conversions[this.props.languageindex]["mon"])
    $(".fc-day-header.fc-widget-header.fc-tue").text(this.props.language_conversions[this.props.languageindex]["tue"])
    $(".fc-day-header.fc-widget-header.fc-wed").text(this.props.language_conversions[this.props.languageindex]["wed"])
    $(".fc-day-header.fc-widget-header.fc-thu").text(this.props.language_conversions[this.props.languageindex]["thu"])
    $(".fc-day-header.fc-widget-header.fc-fri").text(this.props.language_conversions[this.props.languageindex]["fri"])
    $(".fc-day-header.fc-widget-header.fc-sat").text(this.props.language_conversions[this.props.languageindex]["sat"])
}

componentDidUpdate(){
  var self=this;
  var div_value=this.state.x;
  let {date_and_time_control,eventShowTimes} = this.props;
  
  window.$(div_value).find('.fc-day[data-date=' + moment(date_and_time_control.eventStartDate).format("YYYY-MM-DD") + ']').addClass(' fc-border');
  eventShowTimes.map((o, i) => {
    var k=o.start_date;
    let titles=(o.title).split("-");

    o['showDate_m'] = moment(o.start_date).format('YYYY-MM-DD');
    o['showTime_m'] = moment(o.start_date).format('HH:mm:ss');
    o['showcolor']=titles[1]
    })
   
    let groupedEvent = this.groupBy(eventShowTimes, 'showDate_m');
    let eventAvailability = groupedEvent[this.state.eventAvailabilityDate];
    //this.setState({eventAvailability})
    let keys = Object.keys(groupedEvent)
    let selectedcalendermonth=parseInt(eventShowTimes[0].selectedMonthYear[1])+1
    let selectedcalenderyear=new Date(eventShowTimes[0].selectedMonthYear[0]+"-"+selectedcalendermonth+"-"+"01")
    
    let event = keys.map((o) => ({title: groupedEvent[o].length, start: o}));
    let events = keys.map((o) => ({title: groupedEvent[o].length <= 1 ? `${groupedEvent[o].length} `+ this.props.language_conversions[this.props.languageindex]["show"]:
      `${groupedEvent[o].length} `+this.props.language_conversions[this.props.languageindex]["show"], start: o,className:'custom'}));
    var calHeight = $(".calendar-sec").height();  
   
   
    window.$('#calendar').fullCalendar('destroy');
    window.$('#calendar').fullCalendar({
      height: calHeight,
      selectable: true,
      header: {
      left: 'title',
      center: '',
      right: ''
      },
      defaultDate: new Date(date_and_time_control.eventStartDate),
      defaultView: 'month',
      editable: true,
      firstDay: 0, 
      events: events,
      eventRender: function (event, element, view) { 
        
        //self.setState({x:view.el[0]});
        var dateString = event.start.format("YYYY-MM-DD");
        var myArray = ['#24b11342','#ffe8b2','#ffe1e1']; 
        var rand = myArray[Math.floor(Math.random() * myArray.length)];
        let zzz=2;
        eventShowTimes && eventShowTimes.map((xx,yy)=>{
          if(xx.showDate_m === dateString && xx.showcolor === 'green')
          return zzz=0;
          if(xx.showDate_m === dateString && xx.showcolor === 'red' && zzz === 2)
          zzz=1;
        })
       
        
        window.$(view.el[0]).find('.fc-day[data-date=' + dateString + ']').css('background-color', myArray[zzz]);
        if(dateString == moment(date_and_time_control.eventStartDate).format('YYYY-MM-DD')){
          window.$(view.el[0]).find('.fc-day[data-date=' + dateString + ']').addClass(' fc-border'); 
        }
      }
    });
    
    if(Object.keys(events).length > 0)
    window.$('#calendar').fullCalendar('gotoDate', new Date(selectedcalenderyear));	
    else
    window.$('#calendar').fullCalendar('gotoDate', new Date(date_and_time_control.eventStartDate));	
    $('.fc-left').html(self.change_dateshortformat(eventShowTimes[0].selectedMonthYear[0],eventShowTimes[0].selectedMonthYear[1]));
    $(".fc-day-header.fc-widget-header.fc-sun").text(this.props.language_conversions[this.props.languageindex]["sun"])
    $(".fc-day-header.fc-widget-header.fc-mon").text(this.props.language_conversions[this.props.languageindex]["mon"])
    $(".fc-day-header.fc-widget-header.fc-tue").text(this.props.language_conversions[this.props.languageindex]["tue"])
    $(".fc-day-header.fc-widget-header.fc-wed").text(this.props.language_conversions[this.props.languageindex]["wed"])
    $(".fc-day-header.fc-widget-header.fc-thu").text(this.props.language_conversions[this.props.languageindex]["thu"])
    $(".fc-day-header.fc-widget-header.fc-fri").text(this.props.language_conversions[this.props.languageindex]["fri"])
    $(".fc-day-header.fc-widget-header.fc-sat").text(this.props.language_conversions[this.props.languageindex]["sat"]) 
   
  
}
change_dateshortformat(date,month){
  let selectedcalendermonth=parseInt(month)+1
  let selectedcalenderyear=new Date(date+"-"+selectedcalendermonth+"-"+"01")
  let month_val=moment(selectedcalenderyear).format("MMMM");
  let year=moment(selectedcalenderyear).format("YYYY")
  console.log()
  let monthval;
  if(month_val == "January")
  monthval=this.props.language_conversions[this.props.languageindex]["january"]
  if(month_val == "February")
  monthval=this.props.language_conversions[this.props.languageindex]["febrary"]
  if(month_val == "March")
  monthval=this.props.language_conversions[this.props.languageindex]["march"]
  if(month_val == "April")
  monthval=this.props.language_conversions[this.props.languageindex]["april"]
  if(month_val == "May")
  monthval=this.props.language_conversions[this.props.languageindex]["may"]
  if(month_val == "June")
  monthval=this.props.language_conversions[this.props.languageindex]["june"]
  if(month_val == "July")
  monthval=this.props.language_conversions[this.props.languageindex]["july"]
  if(month_val == "August")
  monthval=this.props.language_conversions[this.props.languageindex]["august"]
  if(month_val == "September")
  monthval=this.props.language_conversions[this.props.languageindex]["septemper"]
  if(month_val == "October")
  monthval=this.props.language_conversions[this.props.languageindex]["october"]
  if(month_val == "November")
  monthval=this.props.language_conversions[this.props.languageindex]["november"]
  if(month_val == "December")
  monthval=this.props.language_conversions[this.props.languageindex]["december"]
  if(this.props.languageindex == 0){
    return "<h2>"+moment(selectedcalenderyear).format("MMMM YYYY")+"</h2>";
  }else{
    return "<h2>"+year +" 年 "+monthval+" 月"+"</h2>";
  }
}
groupBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
  }, {});
};
groupByArray = (xs, key) => { 
  return xs.reduce(function (rv, x) { 
  let v = key instanceof Function ? key(x) : x[key]; 
  let el = rv.find((r) => r && r.key === v); 
  if (el) { 
    el.values.push(x); 
  } else { 
    rv.push({ key: v, values: [x] }); 
  } 
  return rv; 
  }, []);
}
covertUpdatedDate(date,month){
  let selectedcalendermonth=parseInt(month)+1
  let selectedcalenderyear=new Date(date+"-"+selectedcalendermonth+"-"+"01")
  let month_val=moment(selectedcalenderyear).format("MMM");
        let month_return;
        if(month_val === "Jan")
        month_return=this.props.language_conversions[this.props.languageindex]["jan"];
        if(month_val === "Feb")
        month_return=this.props.language_conversions[this.props.languageindex]["feb"];
        if(month_val === "Mar")
        month_return=this.props.language_conversions[this.props.languageindex]["mar"];
        if(month_val === "Apr")
        month_return=this.props.language_conversions[this.props.languageindex]["apr"];
        if(month_val === "May")
        month_return=this.props.language_conversions[this.props.languageindex]["may"];
        if(month_val === "Jun")
        month_return=this.props.language_conversions[this.props.languageindex]["jun"];
        if(month_val === "Jul")
        month_return=this.props.language_conversions[this.props.languageindex]["jul"];
        if(month_val === "Aug")
        month_return=this.props.language_conversions[this.props.languageindex]["aug"];
        if(month_val === "Sep")
        month_return=this.props.language_conversions[this.props.languageindex]["sep"];
        if(month_val === "Oct")
        month_return=this.props.language_conversions[this.props.languageindex]["oct"];
        if(month_val === "Nov")
        month_return=this.props.language_conversions[this.props.languageindex]["nov"];
        if(month_val === "Dec")
        month_return=this.props.language_conversions[this.props.languageindex]["dec"];
        if(this.props.languageindex == 0)
        return month_return + " " + moment(selectedcalenderyear).format("YYYY");
        else
        return moment(selectedcalenderyear).format("YYYY")+" 年 "+month_return+" 月";
}
covertUpdatedDate_filter(date,month){
  let selectedcalendermonth=parseInt(month)+1
  let selectedcalenderyear=new Date(date+"-"+selectedcalendermonth+"-"+"01")
  
  return moment(selectedcalenderyear).format("YYYY MM");
}
getUnique(arr, index) {
  const unique = arr
       .map(e => e[index])
       .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);      

   return unique;
}
scrollleft_table(value,hv){
 
  $(".mainscroll").scrollTop(hv);
  
  
}
availableconversion(title){
  title=title.split("-");
  if(title[0] != undefined)
  {
   return title[0];

  }
 
}
changedate_time(datevalue){
  let dates=moment(datevalue).format("DD") // MMM YYYY, h:mm A
  let month=moment(datevalue).format("MMM")
  let year=moment(datevalue).format("YYYY")
  let time=moment(datevalue).format("h:mm A")
  let monthvalue;
  if(month === "Jan"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["jan"]
  }
  if(month === "Feb"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["feb"]
  }
  if(month === "Mar"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["mar"]
  }
  if(month === "Apr"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["apr"]
  }
  if(month === "May"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["may"]
  }
  if(month === "Jun"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["jun"]
  }
  if(month === "Jul"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["jul"]
  }
  if(month === "Aug"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["aug"]
  }
  if(month === "Sep"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["sep"]
  }
  if(month === "Oct"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["oct"]
  }
  if(month === "Nov"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["nov"]
  }
  if(month === "Dec"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["dec"]
  }
  if(this.props.languageindex == 0){
    return moment(datevalue).format("DD MMM YYYY, h:mm A")
  }else{
    return  year+" 年 " +monthvalue+" 月 "+dates +" 日 "+time;
  }

  
}
change_eventsdate(dateval){
  let dates=moment(dateval).format("DD")// MMM YYYY
  let month=moment(dateval).format("MMM")// MMM YYYY
  let year=moment(dateval).format("YYYY")// MMM YYYY
  let monthvalue;
  console.log(this.props.languageindex)
  if(month === "Jan"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["jan"]
  }
  if(month === "Feb"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["feb"]
  }
  if(month === "Mar"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["mar"]
  }
  if(month === "Apr"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["apr"]
  }
  if(month === "May"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["may"]
  }
  if(month === "Jun"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["jun"]
  }
  if(month === "Jul"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["jul"]
  }
  if(month === "Aug"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["aug"]
  }
  if(month === "Sep"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["sep"]
  }
  if(month === "Oct"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["oct"]
  }
  if(month === "Nov"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["nov"]
  }
  if(month === "Dec"){
    monthvalue=this.props.language_conversions[this.props.languageindex]["dec"]
  }
  if(this.props.languageindex == 0){
    return moment(dateval).format("DD MMM YYYY")
  }else{
    return  year+" 年 " +monthvalue+" 月 "+dates +" 日 ";
  }
}
// [START] ST-12659
isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
// [END] ST-12659

render(){
  const { eventShowTimes,date_and_time_control,priceCategory_list,languageindex,scrollvalue,language_conversions } = this.props;
  eventShowTimes.map((o, i) => {
    var k=o.start_date;
    
    o['showDate_m'] = moment(o.start_date).format('YYYY-MM-DD');
    o['showTime_m'] = moment(o.start_date).format('HH:mm:ss');
    })
  console.log("eventShowTimes",eventShowTimes);
  this.backgroundoptions =["#FECD84","#A5C7F5","#71BA64","#D097F0","#F7F19F","#B5EDF3","#D9C297","#A6F0B9","#FB9CC0","#D6CC1B","#929E92","#4AD1B1","#8C65A0","#A80717","#FF0049","#D89D61","#3D7717","#AE9FD6","#EC826A","#FFC7CE"];
  let totalseats=0;
  let bgindex=0,priceclassindex=0;
  let priceclass=[];
  if(scrollvalue)
      this.scrollleft_table(scrollvalue.scrollLeftvalue,scrollvalue.scrollrightvalue);
  let filtersresults=eventShowTimes.filter(o => (this.covertUpdatedDate_filter(o.selectedMonthYear[0], o.selectedMonthYear[1])) == moment(date_and_time_control.eventStartDate).format("YYYY MM"));
  if(this.props.calender_seat_selection){
    this.props.calender_seat_selection.map((e,i) => {
     for(var k=0; k< e[4].length;k++){
       var str= e[4][k];
       var splval=str.split(":");
       if(splval[2] > 0){
         priceclass[priceclassindex]=splval[1];
         priceclassindex++;
       }
     }
    
    })
    
    var uniqueNames = [];
    $.each(priceclass, function(i, el){
        if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });
  }
  let iccThumb;
  if(!localStorage.getItem("summaryImage")){
      iccThumb="/images/sistic.png";
  }else{
      iccThumb=localStorage.getItem("summaryImage");
      if(!this.isValidHttpUrl(iccThumb)){
        iccThumb=this.props.imagekeyvalidations(iccThumb);
      }
  }
   // [START] ST-12241
   let logoImagePath;
   if(!localStorage.getItem("logoImage")){
       logoImagePath = "/images/sistic-logo.png";
   } else {
       logoImagePath = localStorage.getItem("logoImage");
       logoImagePath = this.props.imagekeyvalidations(logoImagePath);
   }
   // [END] ST-12241
 
  priceCategory_list && priceCategory_list.map((list,i) =>{
  totalseats=totalseats+parseInt(list.seatAvailability);
})
  return(
    <Fragment>
    <div className="container-fluid">
    <div className="row">
      <header className="container-fluid">
        <div className="nav-brand float-left" style={{width:'7vw'}}><img src={logoImagePath} className="img-fluid" style={{objectFit: 'contain', width:'100%', height: '5vh'}} alt="SISTIC" /></div>
      </header>
      <section className="main-content container-fluid choose-date">
        <div className="row top-sec my-4">
          <div className="col-lg-5 col-md-12 d-flex">
          <div className="show-thumb"><img src={iccThumb} alt='Thumbnail' style={{objectFit: 'contain', width:'100%'}} /></div>
            <div className="show-detail">
              {/* <div className="badge badge-primary">Action</div> */}
              <h5 dangerouslySetInnerHTML={{__html:this.props.languageconversion(date_and_time_control.eventName,languageindex)}} ></h5>
              <h6 className="venue" dangerouslySetInnerHTML={{__html:this.props.languageconversion(date_and_time_control.eventVenue,languageindex)}} ></h6>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 res-md-mt">
            <div className="row d-flex">
              <div className="col-md-4">
                <div className="col px-0">
                  <h6><img src="/images/tick-green.png" alt="icon" /> {language_conversions[languageindex]["dateandtime"]}</h6>
                  <span className="desc">
                    {/* {moment(date_and_time_control.eventStartDate).format("DD MMM YYYY, h:mm A")} */}
                    {
                      this.changedate_time(date_and_time_control.eventStartDate)
                    }
                    </span>
                </div>
              </div>
              <div className={ this.props.calender_seat_selection && this.props.calender_seat_selection.length > 0 ? "col-md-4":"col-md-4 in-active" }>
                <div className="col">
                  <h6><img src="/images/tick-green.png" alt="icon" />  {language_conversions[languageindex]["category"]}</h6>
                  <span className="desc">{ this.props.calender_seat_selection ? 
                  this.props.calender_seat_selection.map((e,i) => {
                  let s=e[1];
                  
                  if(s.match(/\d+/))
                    bgindex=s.match(/\d+/)[0] -1;
                  else
                    bgindex=i;
                    return(
                      <span className="float-left"> 
                      <span className="float-left color-blk" style={{background: this.backgroundoptions[bgindex], borderColor:  this.backgroundoptions[bgindex]}} />
                      { this.props.languageconversion(e[1],languageindex) }</span>                                      
                    );
                  
                  }):"--" }</span>
                </div>
              </div>
              <div className={ uniqueNames && uniqueNames.length > 0 ? "col-md-4":"col-md-4 in-active"}>
                <div className="col">
                  <h6><img src="/images/tick-green.png" alt="icon" /> {language_conversions[languageindex]["priceclass"]}</h6>
                <span className="desc text-truncate">{ uniqueNames && uniqueNames.length > 0 ? 
                uniqueNames.map((e,i)=>{
                  let replace_priceclass=e.replace('["','');
                  replace_priceclass=replace_priceclass.replace('"]','');
                  replace_priceclass=replace_priceclass.replace('""','-');
                  return(<span className="clearfix" key={i} dangerouslySetInnerHTML={{__html: this.props.languageconversion(replace_priceclass,languageindex)}}></span>  );
                })
                :"--"}</span> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row content-sec">
          <div className="col-xl-9 col-lg-8 col-md-8">
            <div className="calendar-sec bglight-block">
              <div id="calendar" />
              <div className="calendar-legend">
              <div>
                <div className="color-blk" style={{background:'#ffe1e1'}}></div>
                <span>{language_conversions[languageindex]["soldout"]}</span>
              </div>
              <div>
                <div className="color-blk" style={{background:'#ffe8b2'}}></div>
                <span>{language_conversions[languageindex]["fastfilling"]}</span>
              </div>
                <div>
                  <div className="color-blk" style={{background: '#c3f1c3'}} />
                  <span>{language_conversions[languageindex]["available"]}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-4">
            <div className="bglight-block h-md-auto">
              <div className="row snippet-head mb-3">
              {console.log("filtersresults",filtersresults)}
              {
              filtersresults.length > 0 ?
              this.change_eventsdate(date_and_time_control.eventStartDate) :
              this.covertUpdatedDate(eventShowTimes[0].selectedMonthYear[0],eventShowTimes[0].selectedMonthYear[1])
              } - {language_conversions[languageindex]["eventavaliability"]}</div>
              <div className="mainscroll">
              { 
              filtersresults.length > 0 && eventShowTimes && eventShowTimes.filter(o => o.showDate_m === moment(date_and_time_control.eventStartDate).format("YYYY-MM-DD")).map((es, ind) => {
              let bordervalue,time1,time2,time3;
              time1=moment(date_and_time_control.eventStartDate).format("hh:mm A");
              time2=this.availableconversion(es.title);
              time3=time2.split(":");
              if(time3[0].length === 1)
              time2="0"+time3[0]+":"+time3[1];
              if(time1 == time2)
              {
              bordervalue="2px solid red";
              }
              else{
              bordervalue="2px";
              }
              return ( <React.Fragment key={ind}> 
                  <div  className={((es.event_textColor) === 'green') || ((es.event_textColor) === 'red') ?"snippet-avail col d-flex px-0 mb-2":"snippet-avail col d-flex px-0 mb-2 in-active"}  style={{border:bordervalue}}>
                  <div className="avail-seat"><img className="img-fluid" src="/images/armchair-icon.svg" alt="Icon" />
                  {es.event_textColor === 'black' && <span className="badge badge-danger">Sold Out</span>}
                  </div>
                  <div className="seat-count">
                  <span className="time">{ this.availableconversion(es.title)}</span>
                    <span className="count"> {es.event_textColor === "green" && " "+language_conversions[languageindex]["seatsavailable"]}
                    {es.event_textColor === 'black' && "Sold Out"}
                    </span>
                    <span className="count"> {es.event_textColor === "red" && " "+language_conversions[languageindex]["fastfilling"]}
                    
                    </span>
                  </div>
                  </div>
                  </React.Fragment>);
                  
              }
              )
              
            }
            { filtersresults.length <= 0 && eventShowTimes && eventShowTimes.map((es, ind) => {
              let bordervalue="2px";
              if(es.selectedMonthYear[0] === moment(es.showDate_m).format("YYYY") )
             return ( <React.Fragment key={ind}> 
              <div  className={((es.event_textColor) === 'green') || ((es.event_textColor) === 'red') ?"snippet-avail col d-flex px-0 mb-2":"snippet-avail col d-flex px-0 mb-2 in-active"}  style={{border:bordervalue}}>
              <div className="avail-seat"><img className="img-fluid" src="/images/armchair-icon.svg" alt="Icon" />
              {es.event_textColor === 'black' && <span className="badge badge-danger">Sold Out</span>}
              </div>
              <div className="seat-count">
              <span className="time">{ this.availableconversion(es.title)}</span>
                <span className="count"> {es.event_textColor === "green" && " "+language_conversions[languageindex]["seatsavailable"]}
                {es.event_textColor === 'black' && "Sold Out"}
                </span>
                <span className="count"> {es.event_textColor === "red" && " "+language_conversions[languageindex]["seatsavailable"]}
                
                </span>
              </div>
              </div>
              </React.Fragment>);
            
            // <div  className={"snippet-avail col d-flex px-0 mb-3"}  style={{border:'2px solid red'}}>
            //     <div className="avail-seat"><img className="img-fluid" src="/images/armchair-icon.svg" alt="Icon" />
            //     </div>
            //     <div className="seat-count">
            //     <span className="time">{ moment(date_and_time_control.eventStartDate).format("hh:mm A") }</span>
            //       <span className="count"> { " Seats Available"}
            //       </span>
            //     </div>
            //     </div> 
          }) }
          </div>
            </div>
          </div>
        </div>
      </section>
    </div>  
  </div>
  <div className="loading-wrap">
    <div className="loading-container">
      <img className="icon" src="/images/spinner.gif" alt="icon" />
      <p>{language_conversions[languageindex]["processing"]}</p>
      <h4>{language_conversions[languageindex]["availabledateandtime"]}</h4>
    </div>
  </div>
  </Fragment>
  );
}
}
export default SelectDate;