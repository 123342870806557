import React, { Component } from "react";
import $ from 'jquery';
import moment from 'moment';
class CategoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Sistic Events',
            
           }
        
     }
     componentDidMount(){
         setTimeout(function() { $(".loading-wrap").addClass("d-none"); }, 1500);   
     }
     getCurrentDate(separator=''){

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
        }
        
        scrollleft_table(value,hv){
          $(".pricing-scroll").scrollLeft(value);
          $(".table-responsive").scrollTop(hv);
          var w= $("#scrolltabletest").width();
         
          $("#scrolltabletest").scrollLeft(value);
          
        }
        categorypricingResult(priceTable,price_table_map,ind,seatSectionList)
        {
          let new_seat_section=[];
          seatSectionList && seatSectionList.map((sectionvalue,sectionindex)=>{
            let filterCatnumber=sectionvalue.SeatLevel[0].priceCategoryNum;
            new_seat_section.push(filterCatnumber);
          });

          var pc;
          let pc2 = [];
          
          new_seat_section.map((k,indexk)=>{
           
            var filterpc=priceTable.filter((x)=> x.priceCatNum == k)
            if(filterpc.length === 0){
              var priceCatId = 23139;
              seatSectionList.map(x => {if(x.SeatLevel[0].priceCategoryNum === k) {
                if (pc2.filter((y) => y.priceCategoryId == x.SeatLevel[0].priceCategoryId ).length == 0) {
                  priceCatId = x.SeatLevel[0].priceCategoryId;
                }
              }} );
              pc2.splice(indexk, 0, {
              priceCatNum: k,
                priceValue: {
                    amount: 0,
                    currency: "HKD",
                    formatted: "HK$10.00"
                },
                priceStatus: 19,
                priceCategoryId: priceCatId,
                seatSectionId: 221400
            })
          } else {
            pc2.push(filterpc[0]);
          }
          });
         
              pc= pc2.map((priceCat, cat_index) =>{
                
                  if(!price_table_map.has(priceCat.priceCategoryId) || !price_table_map.has(priceCat.priceValue.amount)){
                      price_table_map.set(priceCat.priceCategoryId, true); 
                      price_table_map.set(priceCat.priceValue.amount, true); 
                      return(
                          <td id={priceCat.priceCatNum}  style={{ whiteSpace: "nowrap"}} key={priceCat.priceCatNum}>{priceCat.priceValue.amount > 0 ? priceCat.priceValue.currency+' '+priceCat.priceValue.amount.toFixed(2) : '-'}</td>

                          )
                  }
              })
          //Filter value If it is undefined or missing key
          var pc1=pc.filter((o)=>o !== undefined)

          return pc1;
      }
      changedate_time(datevalue){
        let dates=moment(datevalue).format("DD") // MMM YYYY, h:mm A
        let month=moment(datevalue).format("MMM")
        let year=moment(datevalue).format("YYYY")
        let time=moment(datevalue).format("h:mm A")
        let monthvalue;
        if(month === "Jan"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["jan"]
        }
        if(month === "Feb"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["feb"]
        }
        if(month === "Mar"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["mar"]
        }
        if(month === "Apr"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["apr"]
        }
        if(month === "May"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["may"]
        }
        if(month === "Jun"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["jun"]
        }
        if(month === "Jul"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["jul"]
        }
        if(month === "Aug"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["aug"]
        }
        if(month === "Sep"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["sep"]
        }
        if(month === "Oct"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["oct"]
        }
        if(month === "Nov"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["nov"]
        }
        if(month === "Dec"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["dec"]
        }
        if(this.props.languageindex == 0){
          return moment(datevalue).format("DD MMM YYYY, h:mm A")
        }else{
          return  year+" 年 " +monthvalue+" 月 "+dates +" 日 "+time;
        }
      
        
      }
      // [START] ST-12659
      isValidHttpUrl(string) {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
        return url.protocol === "http:" || url.protocol === "https:";
      }
      // [END] ST-12659
     render(){
        const { language_conversions,seatdata,date_and_time_control,scrollvalue,languageindex } = this.props;
        Array.prototype.sortBy = function(p) {
          return this.slice(0).sort(function(a,b) {
            return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
          });
        }
        Array.prototype.sortByPriceCatNumber = function() {
          return this.slice(0).sort(function(a,b) {
            return (a.SeatLevel[0].priceCategoryNum > b.SeatLevel[0].priceCategoryNum) ? 1 : (a.SeatLevel[0].priceCategoryNum < b.SeatLevel[0].priceCategoryNum) ? -1 : 0;
          });
        }

        let objs =seatdata && seatdata.seatSectionList;
        objs = objs && objs.sortByPriceCatNumber();
        
        let seatSectionList_color=objs && objs;
        seatSectionList_color && seatSectionList_color.map((categorylist,ind)=>{
          objs[ind]["colorindex"]= categorylist.SeatLevel[0].priceCategoryNum -1;
        })
        
        let iccThumb;
        if(!localStorage.getItem("summaryImage")){
            iccThumb="/images/sistic.png";
        }else{
            iccThumb=localStorage.getItem("summaryImage");
            if(!this.isValidHttpUrl(iccThumb)){
              iccThumb=this.props.imagekeyvalidations(iccThumb);
            }
        }
        // [START] ST-12241
        let logoImagePath;
        if(!localStorage.getItem("logoImage")){
          logoImagePath = "/images/sistic-logo.png";
        } else {
          logoImagePath = localStorage.getItem("logoImage");
          logoImagePath=this.props.imagekeyvalidations(logoImagePath);
        }
        let seatMapOverviewImg;
        if(localStorage.getItem("seatMapOverviewImg")) {
          seatMapOverviewImg = localStorage.getItem("seatMapOverviewImg");
          seatMapOverviewImg = this.props.imagekeyvalidations(seatMapOverviewImg);
        }
        // [END] ST-12241
        let seatSectionList=objs && objs;
        //let seatSectionList=objs && objs.sortBy('priceCategoryNum');priceCatAlias
        
        //let seatSectionList=objs && objs;
        let ind=seatSectionList && seatSectionList.length;
        if(scrollvalue)
        this.scrollleft_table(scrollvalue.scrollLeftvalue,scrollvalue.scrollrightvalue);
        let priceTableObj=this.props.pricedetails;
        
        this.backgroundoptions =["#FECD84","#A5C7F5","#71BA64","#D097F0","#F7F19F","#B5EDF3","#D9C297","#A6F0B9","#FB9CC0","#D6CC1B","#929E92","#4AD1B1","#8C65A0","#A80717","#FF0049","#D89D61","#3D7717","#AE9FD6","#EC826A","#FFC7CE"];
         return(
             <React.Fragment>
            <div className="container-fluid">
            <div className="row">             
              <header className="container-fluid">
              <div className="nav-brand float-left" style={{width:'7vw'}}><img src={logoImagePath} className="img-fluid" style={{objectFit: 'contain', width:'100%', height: '5vh'}} alt="SISTIC" /></div>
              </header>
              
              <section className="main-content container-fluid choose-date">
                <div className="row top-sec my-4">
                  <div className="col-lg-5 col-md-12 d-flex">
                  <div className="show-thumb"><img src={ iccThumb }  alt="Thumbnail" style={{objectFit: 'contain', width:'100%'}} /></div>
                    <div className="show-detail">
                      {/* <div className="badge badge-primary">Action</div> */}
                      <h5 dangerouslySetInnerHTML={{__html:this.props.languageconversion(date_and_time_control.eventName,languageindex)}} ></h5>
                      <h6 className="venue" dangerouslySetInnerHTML={{__html:this.props.languageconversion(date_and_time_control.eventVenue,languageindex)}} ></h6>
           
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 res-md-mt">
                    <div className="row d-flex">
                      <div className="col-md-4">
                        <div className="col px-0">
                          <h6><img src="/images/tick-green.png" alt="icon" /> {language_conversions[languageindex]["dateandtime"]}</h6>
                          <span className="desc">
                          {
                            this.changedate_time(date_and_time_control.eventStartDate)
                          }
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 in-active">
                        <div className="col px-0">
                          <h6><img src="/images/tick-green.png" alt="icon" /> {language_conversions[languageindex]["category"]}</h6>
                          <span className="desc text-truncate">--</span>
                        </div>
                      </div>
                      <div className="col-md-4 in-active">
                        <div className="col px-0">
                          <h6><img src="/images/tick-green.png" alt="icon" /> {language_conversions[languageindex]["priceclass"]}</h6>
                          <span className="desc text-truncate">--</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row content-sec">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="bglight-block seat-map">
                      { seatdata.imageURL ? <img className="img-fluid" src={this.props.imagekeyvalidations(seatdata.imageURL)} alt="No Preview available" />:
                        seatMapOverviewImg ? <img className="img-fluid" src={seatMapOverviewImg} alt="No Preview available" /> :
                      <div class="col px-0 seat-sec"><div class="bacon">{language_conversions[languageindex]["seatnotavailable"] }</div></div>}
                      
                      <p className="font-italic text-center mb-0 p-align-btm" style={{fontWeight: 500}}>{language_conversions[languageindex]["seatplannotdrawn"]}</p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="price-table">
                      <div className="table-responsive" id="scrolltabletest" style={{height: "calc(100vh - 280px)",overflowY: "auto"}}>
                      
                      <table className="table mb-0">
                <tbody>
                  <tr>
                    <td className="p-0" style={{border:"1px solid #dee2e6",borderRight:"none"}}>
                      <table className="table table-borderless mb-0">
                        <thead>
                          <tr>
                            <th>{language_conversions[languageindex]["priceclass"]}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          priceTableObj && priceTableObj.length > 0 && priceTableObj.map((priceTable, index) => {
                          return(<tr><td><span className="text-truncate">{ this.props.languageconversion(priceTable.priceClassAlias,languageindex) }</span></td></tr>)
                          })
                        }
                         
                        </tbody>
                      </table>
                    </td>
                    <td className="border-0 p-0">
                      <div className="pricing-scroll">
                        <table className="table table-bordered mb-0 text-center">
                          <thead>
                           
                              <tr>
                                { 
                                  seatSectionList && seatSectionList.map((categorylist,ind)=>{
                                  return( <th  style={{ whiteSpace: "nowrap"}}><span className="color-blk" style={{background: this.backgroundoptions[categorylist.colorindex]}} />{ this.props.languageconversion(categorylist.priceCatAlias,languageindex)}</th>);
                                })} 
                            </tr>
                           
                          </thead>
                          <tbody>
          {
                priceTableObj && priceTableObj.length > 0 && priceTableObj.map((priceTable, index) => {
                var price_table_map = new Map();

                  return( 
                  <tr>
                      {
                        this.categorypricingResult(priceTable.priceCatList,price_table_map,ind,seatSectionList)
                      }
                     
                  </tr>)
              })
              
          }
                            
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
             </table>
                      
                      
                      
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>  
          </div>
         
          <div className="loading-wrap">
            <div className="loading-container">
              <img className="icon" src="/images/spinner.gif" alt="icon" />
              <p>{language_conversions[languageindex]["processing"]}</p>
              <h4>{language_conversions[languageindex]["forseatselection"]}</h4>
            </div>
          </div>
          </React.Fragment>
         );
     }
    }
    export default CategoryList;