import React, { Component } from "react";
import moment from 'moment';
import $ from 'jquery';
import _ from "lodash";
class ChooseSeat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Sistic Events',
            seatdate: [],
            overviewLoaded: false,
            seatsLoaded: false,
            sectionalias:null,
            triggerprop:1,
            seatNoTextAdjust_x : 0,
            seatNoTextAdjust_y : 0
           }
        
     }
     componentDidMount(){
        setTimeout(function() { $(".loading-wrap").addClass("d-none"); }, 1500);
         this.valueclick=1;
      
        
        window.$('#overviewSeatMap').pictarea({
            rescaleOnResize: true,
            maxSelections: 4,
            normal: {
            fillStyle: 'transparent',
            strokeStyle: 'transparent',
            lineWidth: 1
            },
            active: {
            fillStyle: 'transparent',
            strokeStyle: '#ff0f0f',
            lineWidth: 2
            },
            hover: {
            fillStyle: 'transparent',
            strokeStyle: 'transparent',
            lineWidth: 0,
            shadowColor: 'transparent',
            shadowBlur: 0
            }
         });
         if(this.props.triggerevent){
            this.props.triggerevent.pricecategoryid &&  
            $("#"+this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_0").trigger("click");     
            $("#"+this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_1").trigger("click");    
            $("#"+this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_2").trigger("click");     
            $("#"+this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_3").trigger("click");      
           this.setState({sectionalias:this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_0"})
        } 

     }
   componentWillReceiveProps(nextProps){
    if(nextProps.triggerevent.id !== this.props.triggerevent.id){ 
                $("#"+this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_0").trigger("click");     
                $("#"+this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_1").trigger("click");     
                $("#"+this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_2").trigger("click");     
                $("#"+this.splitsectionalias(this.props.triggerevent.sectionid)+"_"+this.props.triggerevent.pricecategoryid+"_3").trigger("click");     
                $("#"+this.splitsectionalias(nextProps.triggerevent.sectionid)+"_"+nextProps.triggerevent.pricecategoryid+"_0").trigger("click");     
                $("#"+this.splitsectionalias(nextProps.triggerevent.sectionid)+"_"+nextProps.triggerevent.pricecategoryid+"_1").trigger("click");     
                $("#"+this.splitsectionalias(nextProps.triggerevent.sectionid)+"_"+nextProps.triggerevent.pricecategoryid+"_2").trigger("click");     
                $("#"+this.splitsectionalias(nextProps.triggerevent.sectionid)+"_"+nextProps.triggerevent.pricecategoryid+"_3").trigger("click");     
            }
   
   }
     componentDidUpdate(){

      
    if(this.valueclick === 1){

        this.valueclick++;
    }
    this.props.seatdate.imageAvailable &&  this.props.seatdate.seatsAvailableList.map((seat) => {
            $("#seatno_"+seat.inventoryId).css({"opacity":"0"});     
     })
     let ircval=0
     this.props.seat_data.data && this.props.seat_data.data.map((res,irc) => {
        $("#seatno_"+res.seatId).css({"opacity":"1"});
        
    })
         
    }
   splitsectionalias(str){
    if(str !== undefined){
    str=str.split("|");   
    return str[0].trim();
        }
   }
     getUnique(arr, index) {
        const unique = arr
             .map(e => e[index])
             .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);      
      
         return unique;
    }
    scale(scaleNumber) {
        if (scaleNumber > 0 && scaleNumber !== 1) {
            let scaleCss = "scale(" + scaleNumber + "," + scaleNumber + ")";
            return {
                "msTransform": scaleCss,
                "WebkitTransform": scaleCss,
                "transform": scaleCss,
                "msTransformOrigin": "0 0",
                "WebkitTransformOrigin": "0 0",
                "transformOrigin": "0 0",
            };
        }
        else
            return {};
    }

    getSeatMapVisibleScale(parentWidth, parentHeight, mapWidth, mapHeight, ignoreHeightScaling = false) {
        const widthScale = parentWidth / mapWidth;
        let heightScale = parentHeight / mapHeight;
    
        // Ignore height scaling, so only using width for scaling
        if (ignoreHeightScaling) {
            heightScale = widthScale;
        }
    
        // Getting the smallest scale value
        const initialScale = (widthScale < heightScale) ? widthScale : heightScale;
    
        // If calculated scale is larger than 1, return 1 (no scaling)
        // Else, return the scale.
        return (initialScale < 1.0) ? initialScale : 1.0;
    }
    shouldComponentUpdate(nextProps, nextState) {
		
		return (!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState));
    }
    
    getSelectedSectionArea(seatmapInfo) {
		// Initializing selected seatmap's outline
		let options = JSON.stringify({
			strokeColor: "0033F2",
			strokeWidth: 1,
			strokeOpacity: 1,
			fillColor: "000000",
			fillOpacity: 0,
			alwaysOn: true,
		});

        // Setting selected seatmap's outline
        if(seatmapInfo){
        seatmapInfo.map((seat, index) => {
		return (
			<map name="overview">
				<area
					shape="poly"
					coords={seat.seatSectionType && seat.seatSectionType ==="GA" ? '': seat.coordinatesList && seat.coordinatesList[0] }
					id={seat.seatSectionId && seat.seatSectionId }
					className="selectedArea"
					tabIndex="0"
					href="#"
				/>
			</map>
        );
        })
    }
	}

	_onOverviewLoad() {
		this.setState({
			overviewLoaded: true
		});
	}

	_onSeatsLoad() {
		this.setState({
			seatsLoaded: true
        });
        var seatSvgW = $(".set-px").innerWidth();
        var seatSvgH = $(".set-px").innerHeight();
        $(".seat-allocation-image").width(seatSvgW);
        $(".seat-allocation-image").height(seatSvgH);
        var leftcalculation=-(seatSvgW/2);
        $(".seat-allocation-image").css("left",leftcalculation);
        
        if(seatSvgW < 600){
            //style={{MsTransform:"scale(0.5)",WebkitTransform:"scale(0.5)",transform:"scale(0.5)"}}
            $(".bacon").css("transform","scale(0.9)");
            $(".bacon").css("-ms-transform","scale(0.9)");
            $(".bacon").css("-webkit-transform","scale(0.9)");
            $(".seat-allocation-image").css("top","-158px");
            $(".seat-number-text").css("font-size", $(".seat-img").width() >= 40 ?  "13" : "9");
            this.setState({seatNoTextAdjust_x: ($(".seat-img").width() >= 40 ? 8 : 3), seatNoTextAdjust_y: ($(".seat-img").width() >= 40 ? 20 : 10)});
        }
        if((seatSvgW >= 600) && (seatSvgW <= 1000) ){
            //style={{MsTransform:"scale(0.5)",WebkitTransform:"scale(0.5)",transform:"scale(0.5)"}}
            $(".bacon").css("transform","scale(0.7)");
            $(".bacon").css("-ms-transform","scale(0.7)");
            $(".bacon").css("-webkit-transform","scale(0.7)");
            $(".seat-allocation-image").css("top","-240px");
            $(".seat-number-text").css("font-size", $(".seat-img").width() >= 45 ?  "15" :"11");
            this.setState({seatNoTextAdjust_x: ($(".seat-img").width() >= 45 ? 10 : 4), seatNoTextAdjust_y: $(".seat-img").width() >= 45 ? 23 : 12});
        }
        if(seatSvgW > 1000){
            $(".bacon").css("transform","scale(0.5)");
            $(".bacon").css("-ms-transform","scale(0.5)");
            $(".bacon").css("-webkit-transform","scale(0.5)");
            $(".seat-allocation-image").css("top","-325px");
            $(".seat-number-text").css("font-size", "20");
            this.setState({seatNoTextAdjust_x: 8, seatNoTextAdjust_y: 24})
        }
       

	}

	getWidth(coord) {
		if (coord.length == 4) {
			return Math.abs(parseInt(coord[2] - coord[0]));
		}
		else {
			let low = parseInt(coord[0]), high = parseInt(coord[0]), currentCoord = 0;
			for (let i = 0; i < coord.length; i += 2) {
				currentCoord = parseInt(coord[i]);

				if (currentCoord < low)
					low = parseInt(currentCoord);

				if (currentCoord > high)
					high = currentCoord;
			}

			return Math.abs(high - low);
		}
	}

	getHeight(coord) {
		if (coord.length == 4) {
			return Math.abs(parseInt(coord[3] - coord[1]));
		}
		else {
			let low = parseInt(coord[1]), high = parseInt(coord[1]), currentCoord = 0;
			for (let i = 1; i < coord.length; i += 2) {
				currentCoord = parseInt(coord[i]);

				if (currentCoord < low)
					low = parseInt(currentCoord);

				if (currentCoord > high)
					high = currentCoord;
			}

			return Math.abs(high - low);
		}
	}

	getX(coord) {
		let low = parseInt(coord[0]), high = parseInt(coord[0]), currentCoord = 0;
		for (let i = 0; i < coord.length; i += 2) {
			currentCoord = parseInt(coord[i]);

			if (currentCoord < low)
				low = parseInt(currentCoord);
		}

		return low;
	}

	getY(coord) {
		let low = parseInt(coord[1]), high = parseInt(coord[1]), currentCoord = 0;
		for (let i = 1; i < coord.length; i += 2) {
			currentCoord = parseInt(coord[i]);

			if (currentCoord < low)
				low = parseInt(currentCoord);
		}

		return low;
    }
    renderSeatmap(seatmapInfo) {
		if (seatmapInfo) {
            seatmapInfo.map((seat, index) => {
                let seatCoordsArray = JSON.parse("[" + seat.coordinates + "]");
                let x = seatCoordsArray[0];
                let y = seatCoordsArray[1];

                if (seat.seatAngle != "0") {
                    x = this.getX(seatCoordsArray);
                    y = this.getY(seatCoordsArray);
                }

                let result="";
                return(<image className="seat-img"
                           key={index}
                           xlinkHref={"/images/SeatIcon_Selected_"+seat.seatAngle+".gif"}
                           x={ x }
                           y={ y }
                           style={{
                               width: this.getWidth(seatCoordsArray),
                               height: this.getHeight(seatCoordsArray)
                           }}
                    />);
                
            });
            
		}
    }
    scrollleft_table(vvalue,hvalue1){
        if(vvalue)
        $(".seat-sec").scrollLeft(vvalue);
        if(hvalue1)
        $(".seat-sec").scrollTop(hvalue1);
        
      }
      onlyUnique(value, index, self) { 
        return self.indexOf(value) === index;
    }
    changedate_time(datevalue){
        let dates=moment(datevalue).format("DD") // MMM YYYY, h:mm A
        let month=moment(datevalue).format("MMM")
        let year=moment(datevalue).format("YYYY")
        let time=moment(datevalue).format("h:mm A")
        let monthvalue;
        if(month === "Jan"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["jan"]
        }
        if(month === "Feb"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["feb"]
        }
        if(month === "Mar"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["mar"]
        }
        if(month === "Apr"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["apr"]
        }
        if(month === "May"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["may"]
        }
        if(month === "Jun"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["jun"]
        }
        if(month === "Jul"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["jul"]
        }
        if(month === "Aug"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["aug"]
        }
        if(month === "Sep"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["sep"]
        }
        if(month === "Oct"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["oct"]
        }
        if(month === "Nov"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["nov"]
        }
        if(month === "Dec"){
          monthvalue=this.props.language_conversions[this.props.languageindex]["dec"]
        }
        if(this.props.languageindex == 0){
          return moment(datevalue).format("DD MMM YYYY, h:mm A")
        }else{
          return  year+" 年 " +monthvalue+" 月 "+dates +" 日 "+time;
        }
      
        
      }
    // [START] ST-12659
    isValidHttpUrl(string) {
        let url;
        try {
        url = new URL(string);
        } catch (_) {
        return false;  
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }
    // [END] ST-12659
     render(){
        let { language_conversions,languageindex,seatdate,seat_data,date_and_time_control,seatoverview,get_ticket_info_onchange,triggerevent,zoomdata,scrollseatmaptop,scrollseatmapleft, basicConfig } = this.props;
        let subtotal=0,pp=[];
        let iccThumb;
        if(!localStorage.getItem("summaryImage")){
            iccThumb="/images/sistic.png";
        }else{
            iccThumb=localStorage.getItem("summaryImage");
            if(!this.isValidHttpUrl(iccThumb)){
                iccThumb=this.props.imagekeyvalidations(iccThumb);
              }
        }
        this.scrollleft_table(scrollseatmapleft,scrollseatmaptop);
        let options = JSON.stringify({
			strokeColor: "0033F2",
			strokeWidth: 1,
			strokeOpacity: 1,
			fillColor: "000000",
			fillOpacity: 0,
			alwaysOn: true,
        });
        
        let overviewURL="";
        let seatmapInfo,detailURL,allSeats,cordsval;
        if(seatoverview.imageAvailable)
        overviewURL=seatoverview.imageURL;
        if(seatdate)
            if(seatdate.imageAvailable){
            detailURL = seatdate.imageURL;
           
        }
        let filterdpriceCatAlias={};
        
        let ticketvalues=Object.values(get_ticket_info_onchange);
        let array=[];
        
        ticketvalues.map((ticketpricealias,indexresult)=>{
            let priceclassresult=Object.values(ticketpricealias.priceClass);
            priceclassresult.map((subtotalresult,subtotalindex)=>{
                subtotal=subtotal+(parseInt(subtotalresult.priceValue)*parseInt(subtotalresult.priceClassQty));
                array.push(subtotalresult.priceClassAlias); 
            })
        })

        var unique = array.filter( this.onlyUnique ); 
        if(get_ticket_info_onchange === undefined)
        get_ticket_info_onchange={};
        if((get_ticket_info_onchange.length > 0)){
            filterdpriceCatAlias = this.getUnique(get_ticket_info_onchange,"priceClassAlias");
        }
        allSeats = this.renderSeatmap(seatdate.seatsAvailableList);
        cordsval=seatoverview.seatSectionList && this.getSelectedSectionArea(seatoverview.seatSectionList[0].SeatLevel);
        { get_ticket_info_onchange.length > 0 && get_ticket_info_onchange.map((res, ind) => {
            var str=res.discountedPrice;
            if(str == '-')
            {
            str=res.priceValue
            var result = str;     
            }
            else
            {
            var result = str.slice(1); 
            }
            subtotal = subtotal + result*(parseInt(res.qty));
        })}
       { pp = seat_data.data &&  seat_data.data.filter((thing, index, self) =>
            index === self.findIndex((t) => (
            t.category === thing.category
            ))
        ) }
        let count = 0;
        seat_data.data && seat_data.data.map((res, ind) => {
            if(res.seatNum)
            count=parseInt(res.seatNum)+count;
        })
        this.backgroundoptions =["#FECD84","#A5C7F5","#71BA64","#D097F0","#F7F19F","#B5EDF3","#D9C297","#A6F0B9","#FB9CC0","#D6CC1B","#929E92","#4AD1B1","#8C65A0","#A80717","#FF0049","#D89D61","#3D7717","#AE9FD6","#EC826A","#FFC7CE"]
       if(zoomdata){
           zoomdata=zoomdata;
           var scalevale="scale("+zoomdata+")";
           $(".bacon").css('transform',scalevale)
       }
       let bgindex=0;
       if(this.props.triggerevent.pricecategoryname){
        let s=this.props.triggerevent.pricecategoryname; 
        if(s.match(/\d+/))   
        bgindex=s.match(/\d+/)[0] -1;
        else
        bgindex=0;
       }
       let categoryresults="";
       if(pp !== undefined ){
           for(var h=0;h < pp.length;h++){
               if(pp.length-1 === h)
               categoryresults=categoryresults+pp[h].category;
               else
               categoryresults=categoryresults+pp[h].category+",";
           }
       }
        // [START] ST-12241
        let logoImagePath;
        if(!localStorage.getItem("logoImage")){
            logoImagePath = "/images/sistic-logo.png";
        } else {
            logoImagePath = localStorage.getItem("logoImage");
            logoImagePath = this.props.imagekeyvalidations(logoImagePath);
        }
        let seatMapOverviewImg ="";
        if(localStorage.getItem("seatMapOverviewImg")) {
            seatMapOverviewImg = localStorage.getItem("seatMapOverviewImg");
            console.log("seat overview image from storage => " + seatMapOverviewImg);
            seatMapOverviewImg = this.props.imagekeyvalidations(seatMapOverviewImg);
        }
        // [END] ST-12241
        if(!seatdate.imageAvailable){
            $('.seatlegend').hide();
        }
        return(
            <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                <header className="container-fluid">
                    <div className="nav-brand float-left" style={{width:'7vw'}}><img src={logoImagePath} className="img-fluid" style={{objectFit: 'contain', width:'100%', height: '5vh'}} alt="SISTIC" /></div>
                </header>
                <section className="main-content container-fluid choose-date">
                    <div className="row top-sec my-4">
                    <div className="col-lg-5 col-md-12 d-flex">
                    <div className="show-thumb"><img src={ iccThumb } alt="Thumbnail" style={{objectFit: 'contain', width:'100%'}} /></div>
                        <div className="show-detail">
                        {/* <div className="badge badge-primary">Action</div> */}
                        <h5 dangerouslySetInnerHTML={{__html:this.props.languageconversion(date_and_time_control.eventName,languageindex)}} ></h5>
                        <h6 className="venue" dangerouslySetInnerHTML={{__html:this.props.languageconversion(date_and_time_control.eventVenue,languageindex)}} ></h6>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 res-md-mt">
                        <div className="row d-flex">
                        <div className="col-md-4">
                            <div className="col px-0">
                            <h6><img src="/images/tick-green.png" alt="icon" /> {language_conversions[languageindex]["dateandtime"]}</h6>
                            <span className="desc">
                            {
                                this.changedate_time(date_and_time_control.eventStartDate)
                            }    
                            </span>
                            </div>
                        </div>
                        { 

                        categoryresults === "" ?
                        <div className={ this.props.triggerevent.pricecategoryname ? "col-md-4":"col-md-4 in-active" } >
                            <div className="col px-0 ">
                            <h6><img src="/images/tick-green.png" alt="icon" /> {language_conversions[languageindex]["category"]}</h6>
                            <span className="desc">
                            <span className="float-left"> 
                            <span className="float-left color-blk" style={{background: this.backgroundoptions[bgindex], borderColor:  this.backgroundoptions[bgindex]}} />
                            { this.props.languageconversion(this.props.triggerevent.pricecategoryname,languageindex)  }</span>                                
                            </span>
                            </div>
                        </div>:
                        <div className={ ( (pp === undefined) ||(pp.length <= 0)) ? "col-md-4 in-active":"col-md-4" } >
                            <div className="col px-0 ">
                            <h6><img src="/images/tick-green.png" alt="icon" /> {language_conversions[languageindex]["category"]}</h6>
                            <span className="desc">
                                { pp && pp.map((res, ind) => {
                                    let s=res.category; 
                                    let bgindexresult=s.match(/\d+/)?s.match(/\d+/)[0] -1:0;
                                    return(<React.Fragment><span className="float-left" key={ind}> 
                                        {res.category === '-' ?'':<span className="float-left color-blk" style={{background: this.backgroundoptions[bgindexresult], borderColor:  this.backgroundoptions[bgindexresult]}} />}
                                         {res.category === '-' ? 'GA':  this.props.languageconversion(res.category,languageindex) }</span></React.Fragment>);
                                })}
                                
                            </span>
                            </div>
                        </div>
                        }
                        <div className={unique.length > 0 ?"col-md-4":"col-md-4 in-active" }>
                            <div className="col px-0">
                            <h6><img src="/images/tick-green.png" alt="icon" />{language_conversions[languageindex]["priceclass"]}</h6>
                            <span className="desc text-truncate"> { unique.length > 0 && unique.map((ind)=> {
                                    return(<React.Fragment><span className="clearfix"> 
                                         { this.props.languageconversion(ind,languageindex)}</span></React.Fragment>);
                                })} </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row content-sec choose-seat">
                    <div className="col">
                        <div className="bglight-block">
                        <div className="row">
                            <div className="col-lg-4 col-md-6" >
                            <div className="seat-map">
                            { overviewURL ? 
                            <img className="img-fluid" src={this.props.imagekeyvalidations(overviewURL)} id="overviewSeatMap"
                            useMap="#overview"
                            alt={language_conversions[languageindex]["seatnotavailable"]} /> :
                            seatMapOverviewImg ? <img className="img-fluid" src={seatMapOverviewImg} id="defaultOverviewSeatMap"
                                alt={language_conversions[languageindex]["seatnotavailable"]} /> :
                             <div class="col px-0 seat-sec"><div class="bacon">{language_conversions[languageindex]["seatnotavailable"]} </div></div>

                           }
                            <map name="overview">
                                { seatoverview.seatSectionList && seatoverview.seatSectionList.map((o,k)=>{
                                
                                return(
                                o.SeatLevel && o.SeatLevel.map((seat,index)=>{
                                    return( 
                                    //seat.seatSectionType !=="GA" && seat.coordinatesList && seat.coordinatesList.map((l,d)=>{
                                    seat.seatSectionType !=="GA" && seat.coordinatesList && seat.coordinatesList.map((ss,ii) =>{
                                       return( 
                                            <React.Fragment>
                                            <area
                                                shape="poly"
                                                id={seat.seatSectionId && seat.seatSectionId+"_"+seat.priceCategoryId+"_"+ii }
                                                className={seat.seatSectionId && seat.seatSectionId}
                                                coords={ ss }
                                                tabIndex="0"
                                                href="#"
                                                key={index}
                                            />
                                            </React.Fragment>
                                        )
                                        
                                        
                                    }) )
                                    } 
                                    )
                                )
                                }
                                )}
                            </map>
                            </div>
                            <p className="font-italic text-center mb-0 p-align-btm" style={{fontWeight: 500}}>{language_conversions[languageindex]["seatplannotdrawn"]}</p>
                            </div>
                            <div className="col-lg-8 col-md-6">
                            <div className="col px-0 clearfix py-3">
                                <div className="float-left">
                                <span className="fw-500">{language_conversions[languageindex]["seatdetails"]}</span>
                            
                            
                              
                                <h5 className="fw-600"> 
                                { seat_data.data && seat_data.data.map((res, ind) => {
                                    console.log( seat_data.data,'seats',res.levelAlias);
                                    return(<React.Fragment key={ind}> {res.rowAlias? " "+language_conversions[languageindex]["row"]+" "+res.rowAlias +":" : ' '+language_conversions[languageindex]["quantity"]} {res.seatNum? res.seatNum: ''}{(seat_data.data.length > 1 && ind < seat_data.data.length) ? ', ': ''}</React.Fragment>)
                                })}

                                </h5>
                                </div>
                                {/* <button className="btn btn-primary price-btn float-right">$ { subtotal? subtotal.toFixed(2) : 0 }</button> */}
                            </div>
                            <div className="col px-0 seat-sec"  >
                            {/* style={{MsTransform:"scale(0.3)",WebkitTransform:"scale(0.3)",transform:"scale(0.4)"}} */}
                                <div className="bacon">
                               { seatdate.imageAvailable?<React.Fragment>
                                    <svg className="seat-allocation-image custom-svg-style" >
                                    
									<image className="set-px" 
										   xlinkHref={this.props.imagekeyvalidations(detailURL)}
                                           onLoad={(event) => this._onSeatsLoad(event)}
									/>
                                    
									{ seatdate.seatsAvailableList.map((seat, index) => {
                                            let seatCoordsArray = JSON.parse("[" + seat.coordinates + "]");
                                            let seatLeftcordsArray=JSON.parse("["+seat.topLeftCoordinates+"]");
                                            let x = seatLeftcordsArray[0];
                                            let y = seatLeftcordsArray[1];

                                            if (seat.seatAngle != "0") {
                                                x = this.getX(seatCoordsArray);
                                                y = this.getY(seatCoordsArray);
                                            }

                                            // [START] ST-12238: Adding seat number on each seat
                                            let tx = x;
                                            let ty = y + this.state.seatNoTextAdjust_y;
                                            if (seat.seatAngle == 50) {
                                                tx += 6;
                                                ty += 4;
                                            }
                                            // [END] ST-12238: Adding seat number on each seat
                                            let result="";
                                       
                                            return(<React.Fragment key={index+'i'}>
                                                    <image className="seat-img"
                                                    key={index}
                                                    id={"seatno_"+ seat.inventoryId}
                                                    xlinkHref={"/images/SeatIcon_Selected_"+seat.seatAngle+".gif"}
                                                    x={ x }
                                                    y={ y }
                                                    style={{
                                                        width: this.getWidth(seatCoordsArray),
                                                        height: this.getHeight(seatCoordsArray),
                                                        opacity:'0'
                                                    }}
                                                />
                                                <text class="seat-number-text"
                                                    key={index + 't'}
                                                    fill="#000000" 
                                                    x={ seat.seatAlias.length > 1 ? tx + this.state.seatNoTextAdjust_x : tx + (this.state.seatNoTextAdjust_x === 8 ? this.state.seatNoTextAdjust_x + 10: this.state.seatNoTextAdjust_x + 4)}
                                                    y={ ty }
                                                    alignmentBaseline="middle"
                                                    textAnchor="start" 
                                                    fontWeight="bold"
                                                    fontFamily="Verdana"
                                                    style= {{opacity: basicConfig.seatNumberingDisplayEnable ? '1' : '0'}}>{seat.seatAlias}
                                                </text>
                                                </React.Fragment>);
                                                }) }

                                            { seatdate.seatsUnavailableList.map((seat, index) => {
                                            let seatCoordsArray = JSON.parse("[" + seat.coordinates + "]");
                                            let seatLeftcordsArray=JSON.parse("["+seat.topLeftCoordinates+"]");
                                            let x = seatLeftcordsArray[0];
                                            let y = seatLeftcordsArray[1];
                                            if (seat.seatAngle != "0") {
                                                x = this.getX(seatCoordsArray);
                                                y = this.getY(seatCoordsArray);
                                            }

                                            let result="";
                                       
                                            return(<image className="seat-img"
                                                    key={index}
                                                    xlinkHref={"/images/Seat_Unavail_"+seat.seatAngle+".gif"}
                                                    x={ x }
                                                    y={ y }
                                                    style={{
                                                        width: this.getWidth(seatCoordsArray),
                                                        height: this.getHeight(seatCoordsArray)
                                                    }}
                                                />);
                                            
                                                }) }

								</svg>
                                
                                
                                </React.Fragment>
                                : language_conversions[languageindex]["seatnotavailable"] }
                                </div>
                                
                            </div>
                            <div className="col px-0 text-center seatlegend">
                               
                             <b>
                              <img src="/images/SeatIcon_Selected_360.gif" />&nbsp;<span class="desc">{language_conversions[languageindex]["seatseleted"] } </span>
                              <img src="/images/Seat_Unavail_360.gif" />&nbsp;<span class="desc">{language_conversions[languageindex]["unavailableseat"] }</span> 
                              <img src="/images/SeatIcon_Avail_0.gif" />&nbsp;<span class="desc">{language_conversions[languageindex]["availableseat"] }</span>
                            </b>
                           </div>

                            </div>
                            
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                </div>  
            </div>
          
            <div className="loading-wrap">
                <div className="loading-container">
                <img className="icon" src="/images/spinner.gif" alt="icon" />
                <p>{language_conversions[languageindex]["processing"]}</p>
                <h4>{language_conversions[languageindex]["seatinformation"]}</h4>
                </div>
            </div>
            </React.Fragment>
         );
     }
    }
    export default ChooseSeat;