import React, { Component } from 'react';
class WelcomeMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Ticket',
        }
    }
    componentDidMount(){
      
    }
    greatingsmessage(){
        var greeting;
        var time = new Date().getHours();
        
        if (time > 5 && time < 12) {
          greeting = "Good Morning";
        } else if (time < 16) {
          greeting = "Good Afternoon";
        } else if (time < 20){
          greeting = "Good evening";
        }else{
          greeting = "Good day";
        }
        return greeting;
      }
    render() { 
       // [START] ST-12241
       let logoImagePath;
       if(!localStorage.getItem("logoImage")){
           logoImagePath = "/images/sistic-logo.png";
       } else {
           logoImagePath = localStorage.getItem("logoImage");
           logoImagePath = this.props.imagekeyvalidations(logoImagePath);
       }
       // [END] ST-12241
        return ( 
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                {/* header Starts here */}
                <header className="container-fluid">
                    <div className="nav-brand float-left" style={{width:'7vw'}}><img src={logoImagePath} className="img-fluid" style={{objectFit: 'contain', width:'100%', height: '5vh'}} alt="SISTIC" /></div>
                    <div className="float-right user-encl">
                   </div>
                </header>
                {/* header Ends here */}
                {/* Content Section Starts here */}
                <section className="main-content container-fluid thanking">
                    <div>
                    <img src="/images/snacks-icon.png" alt="icon" />
                    <h5 className="mb-0"><small>Thanks for Booking</small><br />Enjoy your Show !</h5>
                    </div>
                </section>
                {/* Content Section Starts here */}
                </div> 
            </div>
            
        </React.Fragment>
        );
    }
}
export default WelcomeMessage;